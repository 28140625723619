<template>
    <div>
        <layout-header title="이메일 회원가입" btn-left="back"></layout-header>

        <div class="wrap p-email-join">
            <div class="container">
                <div class="content">
                    <div class="img-wrap">
                        <img alt="" src="/assets/images/member/img_email_join.png">
                    </div>
                    <div class="join-form">
                        <form @submit.prevent="doJoin">
                            <fieldset class="join-field">
                                <legend>회원가입 정보</legend>
                                <div class="form-row">
                                    <label for="email">이메일</label>
                                    <input id="email" v-model="coAccountVO.coEmailId" class="inp-member" name=""
                                           type="text">
                                </div>
                                <div class="form-row">
                                    <label for="password">비밀번호</label>
                                    <input id="password" v-model="coAccountVO.passwd" class="inp-member" name=""
                                           placeholder="숫자, 영문 혼합 6자리 이상" type="password">
                                </div>
                                <div class="form-row">
                                    <label for="password2">비밀번호</label>
                                    <input id="password2" v-model="coAccountVO.passchk" class="inp-member" name=""
                                           placeholder="숫자, 영문 혼합 6자리 이상" type="password">
                                </div>
                            </fieldset>
                            <fieldset class="agree-field">
                                <legend>회원가입 약관</legend>
                                <div class="check-box">
                                    <input id="agree1" v-model="coAccountVO.isMarketing" type="checkbox">
                                    <label for="agree1">본인은 성인이며, 만 14세 미만의 아동이 아님을 확인합니다. (필수)</label>
                                </div>
                                <div class="check-box">
                                    <input id="agree2" v-model="coAccountVO.isPrivacyPolicy" type="checkbox">
                                    <label for="agree2">마샤와 곰의
                                        <router-link :to="{ path: '/pages/termsofservice'}" target="_blank"><span>이용약관</span></router-link>및
                                        <router-link :to="{ path: '/pages/privacypolicy'}" target="_blank"><span>개인정보 수집 및 이용</span></router-link>
                                        에 동의합니다. (필수)</label>
                                </div>
                            </fieldset>
                            <div class="button-area">
                                <button class="btn-member" type="submit">가입하기</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LayoutHeader from "@/pages/layouts/layoutHeader";
import commonUtils from "@/commons/utils/common.utils";

export default {
    name: "emailJoin",
    components: {LayoutHeader},
    data() {
        return {
            coAccountVO: {},
            email: '',
            password: '',
            passwordChk: '',
            agree1: false,
            agree2: false,
        }
    },
    created() {
        this.getAccount()
    },
    mounted() {
    },
    methods: {

        doJoin() {

            if (!this.coAccountVO.isMarketing || !this.coAccountVO.isPrivacyPolicy) {
                this.swalUtils.gritter("처리 오류", "이용 약관 및 개인정보 수집 및 이용에 동의하지 않았습니다.", "warning", 3000)
                return
            }


            const self = this

            this.$eventBus.$emit("startProgress")

            self.axiosCaller.post(self, self.APIs.CO_ACCOUNT + "/", self.coAccountVO, (res) => {
                console.log(res)

                const result = res.data
                self.$eventBus.$emit("doneProgress")

                if (result.status === "ok") {
                    this.coAccountVO = result.coAccountVO

                    // self.axiosCaller.post( self, self.APIs.CO_ACCOUNT + "/doSendAuth/" + self.coAccountVO.caidx, {
                    //     caidx: self.coAccountVO.caidx
                    // }, (res) => {
                    //     const result = res.data
                    //     if ( result.status === "ok" ) {
                    //
                    //         this.$eventBus.$emit("doneProgress")
                    //
                    //         self.swalUtils.gritter("인증메일 발송", "인증메일이 발송되었습니다. <br>메일 링크를 클릭하여 가입을 완료해주세요.", "info")
                    //             .then((result) => {
                    //
                    //                 self.$router.push({
                    //                     name: "emailJoinAuth",
                    //                     query: {
                    //                         caidx: self.coAccountVO.caidx
                    //                     }
                    //                 })
                    //
                    //             })
                    //     } else if (result.status === "fail") {
                    //         this.swalUtils.gritter("처리 오류", result.msg, "warning", 3000);
                    //     }
                    // })

                    self.$router.push({
                        name: "emailJoinAuth",
                        query: {
                            caidx: self.coAccountVO.caidx
                        }
                    })

                } else if (result.status === "fail") {
					console.log(result)
					self.swalUtils.gritter("이미 가입된 계정입니다.", (commonUtils.nullCheck(result.duplicateService) ? 'e-mail' : result.duplicateService) + "으로 로그인 해 주세요.", "warning", 3000)
						.then(t => {
							self.$router.push({
								name: "snsJoin"
							})
						})
                    // this.swalUtils.gritter("처리 오류", result.msg, "warning", 3000);
                } else if (result.status === "error") {
                    this.swalUtils.gritter("처리 오류", "시스템 오류로 처리되지 않았습니다. 관리자에게 문의하세요", "warning", 3000);
                }
            })

        },

        getAccount() {
            const self = this
            const caidx = this.$route.query.caidx
            if (!self.stringUtils.isEmpty(caidx)) {

                this.$eventBus.$emit("startProgress")
                this.axiosCaller.get(this, this.APIs.CO_ACCOUNT + "/", {
                    caidx: caidx
                }, (res) => {
                    self.$eventBus.$emit("doneProgress")
                    const result = res.data
                    self.coAccountVO = new self.CoAccountVO();
                    self.coAccountVO.coEmailId = result.coAccountVO.coEmailId
                    self.coAccountVO.userName = result.coAccountVO.userName
                    self.coAccountVO.isSnsLogin = result.coAccountVO.isSnsLogin
                    self.coAccountVO.snsService = result.coAccountVO.snsService
                    self.coAccountVO.snsId = result.coAccountVO.snsId
                    self.coAccountVO.caidx = result.coAccountVO.caidx
                })
            }
        },

        isEqPassword() {
            return this.passwordChk === this.passwordChk
        },

        isAvailablePassword() {
            return true;
        },

        isAvailableEmail() {
            return true;
        }
    },
}
</script>

<style scoped>

</style>